import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';

import App from './components/App';
import type { Environment } from './config';

declare global {
  // Client Context
  interface Window {
    __CLIENT_CONTEXT: {
      jobAdPreview: any;
      hasError: any;
      uiMode: any;
      environment: Environment;
      language: any;
      locale: any;
    };
    __uiMode: any;
  }
}

const { jobAdPreview, hasError, environment, language, locale } =
  window.__CLIENT_CONTEXT;

const appElement = document.getElementById('app');

if (appElement) {
  hydrateRoot(
    appElement,
    <BrowserRouter>
      <App
        jobAdPreview={jobAdPreview}
        hasError={hasError}
        environment={environment}
        language={language}
        locale={locale}
      />
    </BrowserRouter>,
  );
} else {
  throw new Error("Element id 'app' not found during hydration");
}
